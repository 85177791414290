const Contact = () => {
    const styles = {
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: 'bold'
      };
    return (
        <div class="contact-me" id="contact">
            <div  class="page-header">
                Contact Me
            </div>
            <div class="contact-section">
                <div class="contact-info">
                    <h3>Contact Information</h3>
                    <p style={styles}><strong>Email: </strong> <a >abhinavarora310@gmail.com</a></p>
                    <p style={styles}><strong>Phone: </strong>7878928409</p>
                </div>
        
                <div class="flex-spacer"></div>
        
                <div class="social-media">
                    <h3>Let's Connect</h3>
                    <p style={styles}>You can also find me on various social media platforms. Feel free to connect with me and stay updated on my latest projects, thoughts, and insights.</p>
                    <ul>
                        <li><a href="https://www.linkedin.com/in/abhinav-arora-88b5a0207/" target="_blank"><i class="fab fa-linkedin"></i> LinkedIn</a></li>
                        <li><a href="https://x.com/AbhinavAro68525" target="_blank"><i class="fab fa-twitter"></i> Twitter</a></li>
                        <li><a href="https://github.com/abhinav020104" target="_blank"><i class="fab fa-github"></i> GitHub</a></li>
                    </ul>
                </div>
        
                <div class="flex-spacer"></div>
        
                <div class="business-inquiries">
                    <h3>Business Inquiries</h3>
                    <p style={styles}>For business inquiries, partnerships, or freelance opportunities, please reach out to me directly via email or phone. Let's discuss how we can collaborate and bring your ideas to life!</p>
                </div>
            </div>  
        </div>
    )
}

export default Contact