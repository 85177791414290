import React from 'react'
import HTML from "../images/stack/HTML.png"
import CSS from "../images/stack/CSS.png"
import Javascript from "../images/stack/Javascript.svg"
import Express from "../images/stack/Express.png"
import NodeJs from "../images/stack/NodeJs.svg"
import MongoDB from "../images/stack/MongoDB.svg"
import Redux from "../images/stack/Redux.svg"
import Tailwind from '../images/stack/Tailwind.png'
import C from '../images/stack/C.jpg'
import CPLus from '../images/stack/CPlus.jpg'
import Python from '../images/stack/python.jpg'
import react from '../images/stack/React.png'
import blob from "../images/userAsset/blob vector.png"
import Docker from "../images/stack/Docker.svg"
import Next from "../images/stack/Next.svg"
import k8s from "../images/stack/K8s.svg"
import ts from "../images/stack/Typescript.svg"

const Skills = () => {
    return (
        <div id="skills" className="w-10/12 mx-auto mt-8 mb-16">
            <h1 className="page-header">Skills</h1>

            <div className='flex  skills-section-container'>
                <div className="flex flex-col w-8/12 mt-10 sc">
                    <h2 className="skill-heading">
                        <span className="caps">M</span>e and
                        <br />
                    MyTech Stack
                    </h2>
                    <p className="skill-subHeading text-[19px]">
                    My tech stack is a comprehensive blend of front-end, back-end, and DevOps technologies, enabling me to build modern and scalable web applications. For the front-end, I use React.js for dynamic interfaces, Next.js for performance and SEO through server-side rendering, and TypeScript for improved code maintainability. JavaScript, HTML, and CSS provide the foundation for responsive and visually appealing designs. On the back-end, I leverage MongoDB for handling unstructured data and PostgreSQL for reliable relational data management. For DevOps, Kubernetes automates deployment and scaling, while Docker ensures portability and consistency by packaging applications with their dependencies. This robust stack allows me to develop performant, scalable, and maintainable applications.
                    </p>

                </div>

                <div className="flex flex-wrap h-fit gap-x-8 w-5/12 gap-y-8 mt-12 relative skills-image-container">
                    <img src={blob} class="blob-style" alt="" />

                    <img className="project-skill skills-logo" src='https://img.icons8.com/?size=100&id=40670&format=png&color=000000' title='C' />
                    <img className="project-skill skills-logo scale-110" src='https://img.icons8.com/?size=100&id=40669&format=png&color=000000' title="C++" />
                    <img className="project-skill skills-logo" src='https://img.icons8.com/?size=100&id=13441&format=png&color=000000' title="Python" />
                    <img className="project-skill skills-logo" src={HTML} title="HTML" />
                    <img className="project-skill skills-logo" src={CSS} title="CSS" />
                    <img className="project-skill skills-logo" src={Javascript} title="Javascript" />
                    <img className="project-skill skills-logo" src={react} title="React" />
                    <img className="project-skill skills-logo" src={Tailwind} title="Tailwind" />
                    <img className="project-skill skills-logo" src={Express} title="Express" />
                    <img className="project-skill skills-logo" src={NodeJs} title="Node Js" />
                    <img className="project-skill skills-logo" src={MongoDB} title="Mongo DB" />
                    <img className="project-skill skills-logo" src={Docker} title="Docker" />
                    <img className="project-skill skills-logo" src={ts} title="ts" />
                    <img className="project-skill skills-logo" src={k8s} title="k8s" />
                    <img className="project-skill skills-logo" src={Next} title="Next" />
                </div>
            </div>
        </div>
    )
}

export default Skills