import nptel1 from "../images/userAsset/nptel1.png"
import nptel2 from "../images/userAsset/nptel2.png"
import nptel3 from "../images/userAsset/nptel3.png"
import nptel4 from "../images/userAsset/nptel4.png"
import dsa from "../images/userAsset/dsa.png"
import web_dev  from "../images/userAsset/webdev.png"
import lld from "../images/userAsset/Low-Level-Design.png"
import isro from "../images/userAsset/isro.png"
import EnlargeImage from "./EnlargeImage"
const Certificaiton = ()=>{
    return(
        <div className="w-10/12 mx-auto mt-5 certifications-section bg-[(231,231,231)]">
            <div className="page-header_certifications mb-6 mt-3">My Certifications</div>
            <div class="certifications-container">
                <div class="container-1">
                    <div class="cert-image-holder">
                        <EnlargeImage src={nptel1} onclick="openModal(this.src)"/>
                    </div>
                    <div class="cert-image-holder">
                        <EnlargeImage src={nptel2} onclick="openModal(this.src)"/>
                    </div>
                </div>
                <div class="container-2">
                    <div class="cert-image-holder">
                        <EnlargeImage src={nptel3} onclick="openModal(this.src)"/>
                    </div>
                    <div class="cert-image-holder">
                        <EnlargeImage src={nptel4} onclick="openModal(this.src)"/>
                    </div>
                </div>
                <div class="container-3">
                    <div class="cert-image-holder">
                        <EnlargeImage src={dsa} onclick="openModal(this.src)"/>
                    </div>
                    <div class="cert-image-holder">
                        <EnlargeImage src={web_dev} onclick="openModal(this.src)"/>
                    </div>
                </div>
                <div class="container-3">
                    <div class="cert-image-holder">
                        <EnlargeImage src={lld} onclick="openModal(this.src)"/>
                    </div>
                    <div class="cert-image-holder">
                        <EnlargeImage src={isro} onclick="openModal(this.src)"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Certificaiton