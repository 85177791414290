import React, { useEffect } from 'react'
import Typed from 'typed.js';
import userImage from "../images/userAsset/UserImage-4.jpeg"

const Hero = () => {

    useEffect(() => {
        const options = {
            strings: [
                " Full Stack Developer",
                " Web Developer",
                " Backend Developer",
                " Coder",
            ],
            loop: true,
            typeSpeed: 50,
            backSpeed: 20,
            backDelay: 1000,
        };
        const typed = new Typed(".role", options);
        return () => {
            typed.destroy();
        };
    }, []);

    return (
        <div className='pt-15 w-full pb-10 bg-white flex items-center justify-around hero-container'>
            <div class="hero-section-left">
                <div class="hero-section-heading">Abhinav Arora</div>
                <div class="hero-section-heading hero-section-sub-heading">
                    I am a<span class="role"></span>
                </div>
                <div class="mt-[1rem] text-xl leading-8 text-justify hero-content">
                Final year BTech Computer Science student at Poornima College Of Engineering with a strong foundation in programming, software development. Skilled in languages such as C++ , with hands-on experience in web development and database management. Known for problem-solving abilities, teamwork, and a keen interest in learning new technologies. Seeking an opportunity to apply my skills and contribute to innovative projects in a dynamic environment.
                </div>
            </div>
            <div class="hero-section-right ">
                <div class="user-image">
                    <img src={userImage} alt="" className='scale-' />
                </div>
            </div>            
        </div>
    )
}

export default Hero