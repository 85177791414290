import { useState } from "react";
const EnlargeImage = ({ src }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  console.log(src)
  console.log(isModalOpen);
  return (
    <>
      <img src={src} onClick={openModal} alt="Thumbnail" style={{ cursor: 'pointer' }} />
      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <img src={src} alt="Enlarged" />
        </div>
      )}
    </>
  );
};

export default EnlargeImage;
