import React from 'react'
import { } from '../images/projects/Project1.png'

const NavBar = () => {
    return (
        <div className='mx-auto shadow-lg pb-6 w-screen '>
            <div class="flex justify-center gap-16 text-xl mt-6 navbar">
                <a href="#projects" className='hover:font-semibold'>Projects</a>
                <a href="#skills" className='hover:font-semibold'>Skills</a>
                <a href="#contact" className='hover:font-semibold'>Contact Me</a>
            </div>
        </div>
    )
}

export default NavBar