import React from 'react'
import HTMl from "../images/stack/HTML.png"
import CSS from "../images/stack/CSS.png"
import Javascript from "../images/stack/Javascript.svg"
import Express from "../images/stack/Express.png"
import NodeJs from "../images/stack/NodeJs.svg"
import MongoDB from "../images/stack/MongoDB.svg"
import Redux from "../images/stack/Redux.svg"
import Vercel from "../images/stack/Vercel.svg"

const Projects = () => {
    return (
        <div className="project-section" id="projects">
            <h2 className="page-header">Projects</h2>
            <div className="project-container">
                <div className="project-card" id="project1">
                    <div className="project-number project-number-right">01</div>
                    <div className="project-content project-content-left">

                        <div className="project-skills-container">
                            <img className="project-skill" src={HTMl} alt="" />
                            <img className="project-skill" src={CSS} alt="" />
                            <img className="project-skill" src={Javascript} alt="" />
                            <img className="project-skill" src={Express} alt="" />
                            <img className="project-skill" src={NodeJs} alt="" />
                            <img className="project-skill" src={MongoDB} alt="" />
                            <img className="project-skill" src={Redux} alt="" />
                            <img className="project-skill" src={Vercel} alt="" />
                        </div>

                        <h2 className="project-heading">Study Notion</h2>

                        <p className="project-subHeading">Study Notion is a fully functional Ed-Tech user can signup as instructor or a
                        student , instructor can add new courses , delete , modify his existing
                        courses and can also see statistics of a particular course . Students can
                        enroll in a course , leave a review for the course , access the course
                        contents after enrolling in the course
                        </p>
                        <div className="flex gap-4">
                            <a href="https://github.com/abhinav020104/StudyNotionFinal" target="_blank">
                                <i title="GitHub Link" className="fa-brands fa-github icon"></i>
                            </a>
                            <a href="https://studynotion.codewithabhinav.online/" target="_blank">
                                <i title="Live Link" className="fa-solid fa-link icon"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="project-card" id="project2">
                    <div className="project-number project-number-right">02</div>
                    <div className="project-content project-content-left">
                        <div className="project-skills-container">
                            <img className="project-skill" src={HTMl} alt="" />
                            <img className="project-skill" src={CSS} alt="" />
                            <img className="project-skill" src={Javascript} alt="" />
                            <img className="project-skill" src={Express} alt="" />
                            <img className="project-skill" src={NodeJs} alt="" />
                            <img className="project-skill" src={MongoDB} alt="" />
                            <img className="project-skill" src={Redux} alt="" />
                            <img className="project-skill" src={Vercel} alt="" />
                        </div>
                        <h2 className="project-heading">Blogify</h2>
                        <p className="project-subHeading">
                        Blogify is an innovative online blog portal designed to empower users to write, search, and read blogs from a vibrant community. Users can easily create new blog posts, explore content from other bloggers, and engage through comments on posts .
                        </p>
                        <div className="flex gap-4">
                            <a href="https://github.com/abhinav020104/Blogify-PWA" target="_blank">
                                <i title="GitHubLink" className="fa-brands fa-github icon"></i>
                            </a>
                            <a href="https://blogify.codewithabhinav.online/" target="_blank">
                                <i title="Live Link" className="fa-solid fa-link icon"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="project-card" id="project3">
                    <div className="project-number project-number-right">03</div>
                    <div className="project-content project-content-left">
                        <div className="project-skills-container">
                            <img className="project-skill" src={HTMl} alt="" />
                            <img className="project-skill" src={CSS} alt="" />
                            <img className="project-skill" src={Javascript} alt="" />
                            <img className="project-skill" src={Vercel} alt="" />
                        </div>

                        <h2 className="project-heading">Tic Tac Toe</h2>
                        <p className="project-sub-heading">
                        This project is a classic Tic Tac Toe game developed using HTML, CSS, and JavaScript. It features a simple and intuitive interface, allowing two players to compete against each other. The game includes dynamic updates and responsive design for an engaging user experience. <br /> website  with real-time gameplay
                        </p>
                        <div className="flex gap-4">
                            <a href="https://github.com/abhinav020104/tic-tac-toe" target="_blank">
                                <i title="GitHubLink" className="fa-brands fa-github icon"></i>
                            </a>
                            <a href="https://tic-tac-toe-abhinav0201.vercel.app/" target="_blank">
                                <i title="Live Link" className="fa-solid fa-link icon"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="project-card" id="project4">
                    <div className="project-number project-number-right">04</div>
                    <div className="project-content project-content-left">
                        <div className="project-skills-container">
                            <img className="project-skill" src={HTMl} alt="" />
                            <img className="project-skill" src={CSS} alt="" />
                            <img className="project-skill" src={Javascript} alt="" />
                            <img className="project-skill" src={Vercel} alt="" />
                        </div>

                        <h2 className="project-heading">Dev Detective</h2>
                        <p className="project-sub-heading">
                        This application allows users to quickly find GitHub profiles by entering a GitHub username. Built with HTML, CSS, and JavaScript, it utilizes the GitHub API to fetch and display user information, including repositories, followers, and more.
                        </p>
                        <div className="flex gap-4">
                            <a href="https://github.com/abhinav020104/dev-detective" target="_blank">
                                <i title="GitHubLink" className="fa-brands fa-github icon"></i>
                            </a>
                            <a href="https://dev-detective-abhinav-0201.vercel.app/" target="_blank">
                                <i title="Live Link" className="fa-solid fa-link icon"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Projects